.videoPopup {
    display: flex;
    background-color: rgba(0, 0, 0, .8);
    justify-content: center;
    align-items: center;
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    transition: all 0.6s linear 0s;
}

.videoPopup_opened {
    visibility: visible;
    opacity: 1;
}

.videoPopup__container {
    position: relative;
    max-width: 700px;
}

.videoPopup__video {
    width: 100%;
}

.videoPopup__close {
    position: absolute;
    top: -40px;
    right: -40px;
    width: 32px;
    height: 32px;
    background-size: contain;
    background-image: url(../../images/close.png);
}

@media screen and (max-width: 880px) {
    .videoPopup__container {
        max-width: 600px;
    }
}

@media screen and (max-width: 726px) {
    .videoPopup__container {
        max-width: 500px;
    }
}