.cookieInfo {
    max-width: 100%;
    display: flex;
    align-items: center;
}

.cookieInfo__text {
    margin: 20px 0 5px 0;
    font-size: 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    color: #434B4D;
}

.cookieInfo__file {
    margin: 0 10px 15px 10px;
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #00C8C9;
    cursor: pointer;
    transition: color .2s;
}

.cookieInfo__file:hover {
    color: #000;
}

.cookieInfo__agree {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.cookieInfo__agree-title {
    font-size: 18px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #000;
    cursor: pointer;
    text-decoration: underline;
    transition: color .2s;
}

.cookieInfo__agree-title:hover{
    color: #00C8C9;
}

.cookieInfo__agree-info{
    display: none;
}

.cookieInfo__agree-info_opened {
    display: block;
}

.cookieInfo__agree-text {
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 1.6;
    color: #434B4D;
}