.cookie__title {
    margin-top: 0;
    font-size: 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
}

.cookie__text {
    margin-bottom: 0;
    font-size: 13px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
}

.cookie__about {
    margin-left: 10px;
    font-size: 13px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    line-height: 1.7;
    color: #000;
    transition: color 0.2s;
}

.cookie__about:hover {
    color: #00C8C9;
}