.information {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 70px 60px 70px;
}


.information__container {
    width: 900px;
    background-color: #fffffff2;
    padding: 30px;
    box-shadow: inset 0 -1em 3em rgba(0, 0, 0, 0.1),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.information__link {
    font-size: 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #00C8C9;
    text-decoration: none;
    transition: color .2s;
    border-bottom: 1px solid #ced0d1;
    transition: border-bottom .2s;
}

.information__link:hover {
    color: #434B4D;
    border-bottom: 1px solid #00C8C9;
}

.information__heading {
    margin-top: 30px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.information__heading::after {
    bottom: 0;
    content: '';
    display: block;
    height: 1px;
    width: calc(100% - 30px);
    position: absolute;
    left: 15px;
    background: #d6dada;
}

.information__title {
    font-size: 22px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
}

@media screen and (max-width: 1118px) {
    .information__container {
        width: calc(100% - 68px);
    }
}

@media screen and (max-width: 780px) {
    .information {
        margin: 10px 35px 60px 35px;
    }

    .information__container {
        width: 100%;
    }
}

@media screen and (max-width: 647px) {
    .information {
        margin: 10px 15px 60px 15px;
    }

    .information__title {
        text-align: center;
    }
}

@media screen and (max-width: 580px) {
    .information__title {
        font-size: 20px;
    }
}

@media screen and (max-width: 488px) {
    .information__container {
        padding: 15px;
    }
}

@media screen and (max-width: 372px) {
    .information__container {
        padding: 5px;
    }

    .information__link {
        margin: 10px;
        font-size: 14px;
    }
}