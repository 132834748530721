.note {
    max-width: 100%;
    margin: 20px 0 60px 0;
    padding: 0 70px;
    display: flex;
    justify-content: center;
}

.note__container {
    max-width: 900px;
    background-color: #fffffff2;
    padding: 30px;
    box-shadow: inset 0 -1em 3em rgba(0, 0, 0, 0.1),
    0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

.note__block_note {
    position: relative;
    border-left: 5px solid #00C8C9;
    padding: 15px;
}

.note__block_note::before, .note__block_note::after {
    content: '';
    display: block;
    height: 1px;
    width: calc(100% - 30px);
    position: absolute;
    left: 15px;
    background: #d6dada;
}

.note__block_note::before {
    top: 0;
}

.note__block_note::after {
    bottom: 0;
}

.note__information {
    display: flex;

}

.note__info {
    font-size: 14px;
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    line-height: 1.7;
    text-align: left;
}

.note__link {
    cursor: pointer;
    text-decoration: none;
    margin: 0 5px;
    color: #00C8C9;
    text-transform: uppercase;
    transition: text-decoration 0.2s;
}

.note__link:hover {
    text-decoration: underline;
} 

.note__qr {
    margin-left: 70px;
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.note__cards {
    margin: 20px 0 40px 0;
    display: flex;
    flex-direction: column;
}

.note__card {
    max-width: 730px;
    display: flex;
    margin: 10px 0 20px 0;
}

.note__doctors {
    display: flex;
    flex-direction: column;
}

.note__doctors-link {
    margin: 0;
    font-size: 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #00C8C9;
    text-decoration: none;
    transition: text-decoration 0.2s;
}

.note__doctors-link:hover {
    text-decoration: underline;
}

.note__img {
    width: 160px;
    margin-right: 20px;
    object-fit: cover;
}

.note__card-info {
    margin: 5px 0 0 0;
    font-size: 15px;
    line-height: 1.7;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    text-align: left;
}

.note__card-contacts {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
    cursor: pointer;
}

.node__card-iconInst {
    margin: 0 15px 0 0;
    width:25px;
    height: 25px;
    opacity: .7;
}

.node__card-iconVk {
    width: 35px;
    height: 35px;
    opacity: .7;
    margin: 0 15px 0 0
}

.note__button {
    margin-top: 30px;
    font-size: 14px;
    height: 25px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #00C8C9;
    text-decoration: none;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #434B4D;
    border-radius: 5px;
    transition: background-color 0.2s;
    align-self: center;
}

.note__button:hover {
    background-color: #00C8C9;
    color: #fff;
}

.note__video {
    margin-top: 40px;
    width: 100%;
}

.note__warning {
    margin-bottom: 30px;
    font-size: 14px;
    line-height: 1.7;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    text-align: right;
}

.note__video-description {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 1.7;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    transition: color 0.2s;
    cursor: pointer;
    text-decoration: underline;
}

.note__video-description:hover{
    color: #00C8C9;
}

.note__video-text {
    max-width: 550px;
    margin: 0 auto 40px;
    display: none;
    text-align: left;
    font-size: 14px;
    line-height: 2;
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    color: #434B4D;
}

.note__video-text_opened {
    display: block;
}

@media screen and (max-width: 1100px) {
    .note__video-text {
        margin-left: 175px;
    }
}

@media screen and (max-width: 990px) {
    .note {
        margin-top: 0px;
    }

    .note__cards {
        margin-top: 40px;
    }
}

@media screen and (max-width: 780px) {
    .note {
        padding: 0 40px;
    }

    .note__cards {
        margin-top: 50px;
    }

    .note__card {
        margin: 25px 0;
    }
}

@media screen and (max-width: 647px) {
    .note {
        padding: 0 20px;
    }
    
    .note__container {
        padding: 20px;
    }

    .note__information {
        flex-direction: column-reverse;
    }

    .note__qr {
        margin-left: 0;
        margin-bottom: 20px;
        align-self: flex-end;
        width: 80px;
        height: 80px;
    }

    .note__cards {
        margin-top: 35px;
    }

    .note__card {
        flex-direction: column;
        align-items: center;
    }

    .note__card_down {
        margin: 60px 0;
    }

    .note__img {
        width: 180px;
        height: 220px;
        object-fit: cover;
    }

    .note__doctors {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .note__doctors-link {
        margin: 20px 0;
    }

    .note__card-info {
        margin-top: 0;
    }

    .note__video-text {
        margin-left: auto;
    }
}