.tab7 {
    max-width: 100%;
    display: flex;
    align-items: center;
}

.tab7__link {
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    text-decoration: none;
    transition: color .2s;
    border-bottom: 1px solid #ced0d1;
    transition: border-bottom .2s;
}

.tab7_link:hover {
    color: #00C8C9;
    border-bottom: 1px solid #00C8C9;
}

.tab7__info-title {
    margin: 50px 0 40px 0;
    font-size: 18px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    text-align: center;
}

.tab7___table {
    margin-bottom: 15px;
    width: 100%;
    border: 1px solid #000;
}

.tab7__table-cellTitle {
    height: 20px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    font-size: 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    text-align: center;
}

.tab7__table-cellTitle:last-child {
    border-right: none;
}

.tab7__tableCell {
    height: 35px;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    font-size: 14px;
    line-height: 1.7;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
}

.tab7__tableCell:last-child {
    border-right: none;
}

.tab7__tableCell-speq {
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #00C8C9;
}

.tab7__tableCell_last {
    border-bottom: none;
}

.tab7__block {
    margin-top: 30px;
}

.tab7__note {
    margin: 0 0 20px 0;
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #00C8C9;
    line-height: 1.7;
}

.tab7__block-note {
    position: relative;
    border-left: 5px solid #00C8C9;
    padding: 15px;
}

.tab7__block-note::before, .tab7__block-note::after {
    content: '';
    display: block;
    height: 1px;
    width: calc(100% - 30px);
    position: absolute;
    left: 15px;
    background: #d6dada;
}

.tab7__block-note::before {
    top: 0;
}

.tab7__block-note::after {
    bottom: 0;
}

.tab7__block-note {
    font-size: 14px;
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    line-height: 1.7;
    text-align: left;
}

.tab7__block-title {
    margin: 30px 0 40px 0;
    font-size: 18px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    text-align: center;
}

.tab7__block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.tab7__container {
    margin-top: 40px;
    margin-bottom: 60px;
    max-width: 500px;
    position: relative;
}

.tab7__info-title {
    margin: 30px 0 40px 0;
    font-size: 18px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    text-align: center;
}

.tab7__img {
    position: absolute;
    top: -50px;
    left: -50px;
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.tab7__video {
    width: 100%;
}

.tab7__video-description {
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    line-height: 1.7;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    transition: color 0.2s;
    cursor: pointer;
    text-decoration: underline;
}

.tab7__video-description:hover{
    color: #00C8C9;
}

.tab7__video-textBlock {
    display: none;
}

.tab7__video-textBlock_opened {
    display: block;
}

.tab7__video-textTitle {
    margin-top: 30px;
    text-align: center;
    font-size: 16px;
    line-height: 1.7;
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    color: #000;
}

.tab7__video-text {
    text-align: left;
    font-size: 14px;
    line-height: 2;
    font-family: "Inter";
    font-style: italic;
    font-weight: 400;
    color: #434B4D;
}

@media screen and (max-width: 780px) {
    .tab7 {
        padding: 0 40px;
    }

    .tab7__container {
        max-width: 400px;
    }

    .tab7__img {
        width: 80px;
        height: 80px;
    }
}

@media screen and (max-width: 572px) {
    .tab7__container {
        max-width: 300px;
    }

    .tab7__img {
        width: 80px;
        height: 80px;
    }
}

@media screen and (max-width: 580px) {
    .tab7__info-title {
        font-size: 16px;
    }

    .tab7__table-cellTitle {
        font-size: 13px;
        font-weight: 500;
    }

    .tab7__tableCell {
        font-size: 11px;
    }

    .tab7__note {
        font-size: 13px;
    }

    .tab7__block-note {
        font-size: 12px;
    }
}

@media screen and (max-width: 488px) {
    .tab7 {
        padding: 0 20px;
    }

    .tab7__tableCell {
        font-size: 10px;
    }

    .tab7__container {
        margin-top: 50px;
        max-width: 100%;
    }

    .tab7__img {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }
}

@media screen and (max-width: 372px) {
    .tab7 {
        padding: 0 15px;
    }
}

@media screen and (max-width: 348px) {
    .tab7 {
        padding: 0 5px;
    }
}