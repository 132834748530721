.burger {
    width: 100%;
    margin-top: 140px;
    position: fixed;
    top:-100%;
    z-index: 1;
    visibility: hidden;
    transition: .2s ease;
}
.burger__container {
    height: 100vh;
    padding: 0 40px;
    background-color: rgb(240, 240, 240);
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.burger__list {
    margin: 20px 0;
    list-style: none;
    padding: 0;
}

.burger__links {
    margin: 0 0 20px 0;
    padding: 0;
}

.burger__link {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 1.2;
    color: #434B4D;
    text-decoration: none;
}

.burger__link:hover {
    color: #00C8C9;
    border-bottom: 1px solid #00C8C9;
}

.burger_opened {
    visibility: visible;
    top: 0;
}

@media screen and (min-width: 990px) {
    .burger_opened {
        display: none;
    }
}

@media screen and (max-width: 780px) {
    .burger__container {
        padding: 0 20px;
    }
}