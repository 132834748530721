.tab8 {
    max-width: 100%;
    display: flex;
    align-items: center;
}

.tab8__link {
    margin: 20px 0 5px 0;
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    line-height: 1.7;
    transition: border-bottom .2s;
}

.tab8__link:hover {
    color: #00C8C9;
    border-bottom: 1px solid #00C8C9;
}

.tab8__list {
    list-style: none;
    margin: 60px 0 40px 0;
}

.tab8__point {
    margin-bottom: 20px;
}

.tab8__info {
    font-size: 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    line-height: 1.7;
    transition: color .2s;
}

.tab8__info:hover {
    color: #00C8C9;
}

@media screen and (max-width: 780px) {
    .tab8 {
        padding: 0 40px;
    }

    .tab8__list {
        padding-left: 0;
    }
}

@media screen and (max-width:488px) {
    .tab8 {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 420px) {
    .tab8__info {
       margin-top: 30px;
       font-size: 15px;
    }
}
