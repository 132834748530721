.navigation {
    max-width: 100%;
    padding: 40px 70px;
}

.navigation__list {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.navigation__link {
    font-size: 15px;
    margin: 0;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    color: #434B4D;
    text-decoration: none;
    text-transform: uppercase;
    transition: color .2s;
    border-bottom: 1px solid #ced0d1;
    transition: border-bottom .2s;
}

.navigation__link:hover {
    color: #00C8C9;
    border-bottom: 1px solid #00C8C9;
}

.navigation__link_bold {
    color: #000;
    border-bottom: none;
}

@media screen and (max-width: 1270px) {
   .navigation {
    padding: 30px 40px;
   }

    .navigation__link {
        font-size: 13px;
    }
}

@media screen and (max-width: 990px) {
    .navigation {
        display: none;
    }
}