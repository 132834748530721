.footer {
    max-width: 100%;
    height: 50px;
    padding: 0px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #00C8C9;
}

.footer__info {
    font-size: 14px;
    margin: 0 0 0 10px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #fff;
}