.menu {
    height: 78px;
    max-width: 100%;
    padding: 0 70px;
    display: flex;
    align-items: center;
    justify-content: end;
}

.menu_color {
    background-color: #00C8C9;
}

.menu__btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    border: none;
    width: 32px;
    height: 35px;
    background-color: inherit;
    cursor: pointer;
    z-index: 2;
}

.menu__bar {
    width: 100%;
    height: 2.5px;
    background-color: #000;
    margin: 3px 0;
    transition: .2s;
}

.menu__bar_close.menu__bar1 {
    transform: translate(0, 11px)
    rotate(-45deg);
}

.menu__bar_close.menu__bar2 {
    opacity: 0;
}

.menu__bar_close.menu__bar3 {
    transform: translate(0, -5px)
    rotate(45deg);
}

@media screen and (min-width: 990px) {
   .menu {
    display: none;
   }

    .menu__btn {
        display: none;
    }
}

@media screen and (max-width: 780px) {
    .menu {
        padding: 0 40px;
    }
}

@media screen and (max-width: 647px) {
    .menu {
        padding: 0 20px;
    }
}