.tab1 {
    max-width: 100%;
    display: flex;
    align-items: center;
}

.tab1__link {
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    text-decoration: none;
    transition: color .2s;
    border-bottom: 1px solid #ced0d1;
    transition: border-bottom .2s;
}

.tab1__link:hover {
    color: #00C8C9;
    border-bottom: 1px solid #00C8C9;
}

.tab1__info-title {
    margin: 30px 0 40px 0;
    font-size: 18px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
    text-align: center;
}

.tab1__list {
    list-style: none;
}

.tab1__info {
    margin: 20px 0 5px 0;
    font-size: 16px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #434B4D;
}

.tab1__file {
    margin: 0 0 15px 10px;
    font-size: 14px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    color: #00C8C9;
    cursor: pointer;
}

@media screen and (max-width: 780px) {
    .tab1 {
        padding: 0 40px;
    }

    .tab1__list {
        padding-left: 0;
    }
}

@media screen and (max-width: 488px) {
    .tab1 {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 420px) {
    .tab1__info {
       margin-top: 30px;
       font-size: 15px;
    }
}